import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

let firebaseConfig

switch (process.env.VUE_APP_MODY_ENV) {
    case 'mody-d':
        firebaseConfig = {
            apiKey: "AIzaSyCZKVAzThxOTnOGy6XLUPkClBF1k1X-hUA",
            authDomain: "mody-d.firebaseapp.com",
            projectId: "mody-d",
            storageBucket: "mody-d.appspot.com",
            messagingSenderId: "985501531881",
            appId: "1:985501531881:web:8b978918b2d516d7513a78",
            measurementId: "G-CCW03KPL5D"
        }
        break
    case 'mody-s':
        firebaseConfig = {
            apiKey: "AIzaSyD_SJq6Rzdae0NPPT9tO32x7Kliy6YHTiw",
            authDomain: "mody-s.firebaseapp.com",
            projectId: "mody-s",
            storageBucket: "mody-s.appspot.com",
            messagingSenderId: "992779750826",
            appId: "1:992779750826:web:7a1075bd361078a392efd3",
            measurementId: "G-8774K5L736"
        };
        break
    case 'mody-p':
        firebaseConfig = {
            apiKey: "AIzaSyCORheDrYbCZs71Wyfq_foPQJsbPoz_jcc",
            authDomain: "mody-p.firebaseapp.com",
            projectId: "mody-p",
            storageBucket: "mody-p.appspot.com",
            messagingSenderId: "314298256947",
            appId: "1:314298256947:web:b2c316fb1614c309265d46",
            measurementId: "G-5DN5HHLTPS"
        };
        break
}

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)